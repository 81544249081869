<script setup lang="ts">
import { validateNotEmpty } from '@mop/shared/utils/validationRules';
import successIcon from '@mop/shared/images/arrow/success.svg?raw';
import useUiFormElement from '@mop/ui/composables/useUiFormElement';

defineOptions({
  name: 'UiFormInput',
  inheritAttrs: false,
});

const props = defineProps({
  modelValue: {
    type: String,
    default: null,
  },
  name: {
    type: String,
    default: null,
  },
  placeholder: {
    type: String,
    default: null,
  },
  autocomplete: {
    type: String,
    default: 'off',
  },
  type: {
    type: String,
    default: 'text',
  },
  required: {
    type: Boolean,
    default: false,
  },
  rules: {
    type: Array,
    default: null,
  },
  skipValidationIcons: {
    type: Boolean,
    default: false,
  },
  autofocus: {
    type: Boolean,
    default: false,
  },
  label: {
    type: String,
    default: '',
  },
});

const emit = defineEmits(['update:modelValue']);

const slots = useSlots();
const { class: className, ...attributes } = useAttrs();
const inputEl = ref<HTMLElement>();
const { validationErrors, register, validate, loadingRef } = useUiFormElement(props);
defineExpose({ validate });

const inputClasses = [
  'form-input',
  'form-input__input',
  {
    'form-input__input--left-slot': slots.left !== undefined,
    'form-input__input--right-slot': slots.right !== undefined,
  },
];

onMounted(() => {
  if (props.autofocus) {
    nextTick(() => {
      inputEl.value?.focus();
    });
  }
});

onBeforeMount(() => {
  register(getCurrentInstance());
});

function handleInput(event: Event, paramValue?: string) {
  let value = paramValue ?? ((event.target as HTMLInputElement)?.value || '');
  if (props.type === 'email') {
    const weiredDashesRegex = /‒|–|—|―/g;
    value = value.replace(weiredDashesRegex, '-');
  }
  emit('update:modelValue', value);
  validate();
}

function handleBlur(event: Event) {
  const value = (event.target as HTMLInputElement).value?.trim() || '';
  handleInput(event, value);
}
</script>

<template>
  <div :class="`form-input-wrapper ${className}`">
    <div class="form-input-container">
      <input
        v-bind="attributes"
        ref="inputEl"
        :name="name"
        :placeholder="placeholder"
        :value="modelValue"
        :type="type"
        :autocomplete="autocomplete"
        :class="inputClasses"
        @blur="handleBlur"
        @input="handleInput"
      />

      <label
        v-if="label"
        :for="name"
        :class="[
          'form-input__inside-label',
          {
            'form-input__inside-label--filled': modelValue,
          },
        ]"
      >
        {{ label }}{{ required ? '*' : '' }}
      </label>

      <slot name="left" />
      <slot name="right" />
    </div>

    <div v-if="validationErrors.length" class="form-error form-error--input">
      {{ $mopI18n.t(validationErrors[0]) }}
    </div>

    <i v-if="loadingRef" class="form-input__loading-icon icon icon--loading-asset" />
    <template v-else-if="!skipValidationIcons">
      <template v-if="validationErrors.length > 0">
        <i class="form-input__validation-icon form-input__validation-icon--error icon icon--x-small" />
      </template>
      <template v-if="validationErrors.length === 0 && validateNotEmpty(modelValue)">
        <div class="form-input__validation-icon form-input__validation-icon--success" v-html="successIcon" />
      </template>
    </template>
  </div>
</template>

<style lang="scss" scoped>
input[type='password']::-ms-clear,
input[type='password']::-ms-reveal {
  display: none;
}
</style>
